import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  BASE_URL,
  HEADER_TOKEN,
  TAG_TOKEN,
  TAG_USERNAME
} from '../models/config';
import { Category } from '../models/category';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http:HttpClient) { }

  baseUrl = BASE_URL;

  get(offset, limit, filter?, name?, sort?) {
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append(HEADER_TOKEN, localStorage.getItem(TAG_TOKEN));
    let params = new HttpParams()
      .append('offset', offset)
      .append('limit', limit);

    if (name != null && name != '') {
      params = params.append('search', name);
    }

    if (filter != null && filter != '') {
      Object.entries(filter).forEach(
        (item) => (params = params.append(item[0], item[1].toString()))
      );
    }

    if (sort != null && sort != '') {
      params = params.append('sort', sort);
    }

    return this.http.get(`${this.baseUrl}/api/collab/category`, {
      headers: headers,
      params: params,
    });
  }

  getById(id) {
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append(HEADER_TOKEN, localStorage.getItem(TAG_TOKEN));

    return this.http.get(`${this.baseUrl}/api/collab/category/${id}`, {
      headers: headers,
    });
  }

  post(category: Category) {
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append(HEADER_TOKEN, localStorage.getItem(TAG_TOKEN));
    let c = category;

    console.log('user', c);
    return this.http.post(`${this.baseUrl}/api/collab/category`, c, {
      headers: headers,
      responseType: 'text',
    });
  }

  put(category: Category) {
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append(HEADER_TOKEN, localStorage.getItem(TAG_TOKEN));
    let c = category;

    console.log('user', c);
    return this.http.put(`${this.baseUrl}/api/collab/category`, c, {
      headers: headers,
      responseType: 'text',
    });
  }
}
